let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
let US_URL = process.env.REACT_APP_LIVE_US_URL;

if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
	US_URL = process.env.REACT_APP_LOCAL_US_URL;
}

export const NAVBAR_URLS = {
	logo: 'https://firebasestorage.googleapis.com/v0/b/adagio-cms.appspot.com/o/common%2Fadagio-logo.svg?alt=media&token=3c4f96a0-09c3-4600-9640-960bb1de1f95',
};

export const NAVBAR_COMPONENT = {
	navigation: [
		{ name: 'HOME', href: '/', current: true, type: 'BOTH' },
		{ 
			name: 'PRODUCTS',
			href: '#',
			parent: 'products',
			children: [
				{
					name: 'iCLAS',
					href: '/products-iclas',
					tab: 0,
					type: 'None'
				},
				{
					name: 'vCLAS',
					href: '/products-vclas',
					tab: 1,
					type: 'BOTH'
				}
			]

		},
		{
			name: 'TECHNOLOGY',
			href: '#',
			parent: '/technology-',
			children: [
				{
					name: 'Fundamentals of ULTC',
					href: '/technology-ultc',
					tab: 0,
					type: 'BOTH',
				},
				{
					name: 'Fundamentals of PFCA',
					href: '/technology-pfca',
					tab: 1,
					type: 'BOTH',
				},
			],
		},
		{
			name: 'CLINICAL EVIDENCE',
			href: '#',
			parent: '/clinical-evidence-',
			children: [
				{
					name: 'Studies',
					href: '/clinical-evidence-studies',
					tab: 0,
					type: 'BOTH'
				},
				{
					name: 'Publications',
					href: '/clinical-evidence-papers',
					tab: 0,
					type: 'BOTH'
				},
				{
					name: 'Presentations',
					href: '/clinical-evidence-presentations',
					tab: 0,
					type: 'BOTH'
				},
				{
					name: 'Meet Us Live',
					href: '/events',
					tab: 0,
					type: 'BOTH',
				}
			],
		},
		{ name: 'CAREERS', href: '/careers', current: false, type: 'BOTH' },
		{ 
			name: 'COMPANY', 
			href: '#',
			children: [
				{
					name: 'About Adagio Medical',
					href: '/about#hero',
					tab: 0,
					type: 'BOTH',
				},
				{
					name: 'Our Mission',
					href: '/about#ourMission',
					tab: 0,
					type: 'BOTH',
				},
				{
					name: 'Leadership Team',
					href: '/about#managementTeam',
					tab: 0,
					type: 'BOTH',
				},
				{
					name: 'Board of Directors',
					href: '/about#boardOfDirectors',
					tab: 0,
					type: 'BOTH',
				},
			]
		},
		{ name: 'INVESTORS', href: '#', children: [
			{
				name: 'Corporate Governance',
				href: '/corporate',
				tab: 0,
				type: 'BOTH'
			},
			{
				name: 'SEC Filings',
				href: '/sec-filing',
				tab: 0,
				type: 'BOTH'
			},
			{
				name: 'Press Releases',
				href: '/press-releases',
				tab: 0,
				type: 'BOTH',
			},
			{
				name: 'Events & Presentations',
				href: '/events-presentations',
				tab: 0,
				type: 'BOTH',
			},
			{
				name: 'FAQs',
				href: '/faq',
				tab: 0,
				type: 'BOTH',
			},
			{
				name: "Sign-up for Updates",
				href: '/register',
				tab: 0,
				type: 'BOTH',
			}
		] },
		{ name: 'PATIENTS', href: '/patients', current: false, type: 'BOTH' },
	],
	userNavigation: [
		{ name: 'Your Profile', href: '/your-profile' },
		{ name: 'Sign out', href: '#' },
	],
	guestNavigation: [{ name: 'Sign in', href: '/sign-in' }],
	user: {
		name: 'Tom Cook',
		email: 'tom@example.com',
		imageUrl:
			'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
	},
	region: {
		eu: {
			desktopText: 'Europe',
			mobileText: 'EU',
			link: EU_URL,
		},
		us: {
			desktopText: 'United States',
			mobileText: 'US',
			link: US_URL,
		},
	},
};
