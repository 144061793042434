import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import { getPageId } from '../helpers/api-utils';
import Spinner from '../components/spinner';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function ProductsVclasUnderConstruction() {
	let pathname = 'products-vclas/67ea0a55b25e693c40a55778';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'products-vclas/657c0c79e16ab33e6d1ed3c1';
	}

	const [data, setData] = useState(null);

    const hero = {
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/cms-server-69a05.appspot.com/o/products%2FAdagio_Aidan-01.jpg?alt=media&token=0083f73b-bdc2-4c2a-b08a-f4b38ffd566a",
        titleSecondLine: "vCLAS™ Ultra-Low Temperature Cryoablation",
        subtitle: "Under Construction",
        explorePath: ""
    }

	useEffect(() => {
		const resObjName = 'productsPage';
		getPageId(pathname, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={hero} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
